<template>
    <FormSection title="お客様情報" class="customer-info-section">
        <!-- 会社名 -->
        <FormFieldGroup
            required
            :valid="!!customerInfo.companyName && !errors.companyName"
            :error="!!errors.companyName"
        >
            <template #title>{{ labels.customerInfo.companyName }}</template>
            <template #content>
                <CompanyField
                    name="customer_info_company_name"
                    required
                    :prefix="customerInfo.companyName?.prefix"
                    :suffix="customerInfo.companyName?.suffix"
                    :model-value="customerInfo.companyName?.name"
                    placeholder="例）ネクスウェイ"
                    :valid="!!customerInfo.companyName && !errors.companyName"
                    :error="!!errors.companyName"
                    @update="handleUpdateCompanyName($event)"
                    @keyup="handleUpdateCompanyName($event)"
                />
            </template>
            <template #sub-content>
                <HelpText>
                    法人格を前株か後株のいずれか片方で選択してください。
                </HelpText>
                <HelpText>会社名は法人格を含めずご入力ください。</HelpText>
                <FieldErrorText v-if="errors.companyName">{{
                    errors.companyName
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 会社名フリガナ -->
        <FormFieldGroup
            required
            :valid="!!customerInfo.companyNameKana && !errors.companyNameKana"
            :error="!!errors.companyNameKana"
        >
            <template #title>{{
                labels.customerInfo.companyNameKana
            }}</template>
            <template #content>
                <InputField
                    name="customer_info_company_name_kana"
                    :model-value="customerInfo.companyNameKana"
                    placeholder="例）カイシャメイ"
                    required
                    :pattern="patternKatakanaStr"
                    block
                    :valid="
                        !!customerInfo.companyNameKana &&
                        !errors.companyNameKana
                    "
                    :error="!!errors.companyNameKana"
                    @update:model-value="
                        handleUpdate('companyNameKana', $event)
                    "
                    @keyup="handleUpdate('companyNameKana', $event, true)"
                />
            </template>
            <template #sub-content>
                <HelpText
                    >全角カナ、全角スペース、全角ハイフン、半角ハイフンのみ</HelpText
                >
                <FieldErrorText v-if="errors.companyNameKana">{{
                    errors.companyNameKana
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- <FormFieldGroup
            :valid="!!customerInfo.corpMyNumber && !errors.corpMyNumber"
            :error="!!errors.corpMyNumber"
        >
            <template #title>法人マイナンバー</template>
            <template #content>
                <div>
                    <InputField
                        :model-value="customerInfo.corpMyNumber"
                        :maxlength="100"
                        :valid="
                            !!customerInfo.corpMyNumber && !errors.corpMyNumber
                        "
                        :error="!!errors.corpMyNumber"
                        @update:model-value="
                            handleUpdate('corpMyNumber', $event)
                        "
                        @keyup="handleUpdate('corpMyNumber', $event, true)"
                    />
                    &nbsp;
                    <button
                        class="customer-info-section-button"
                        type="button"
                        @click="handleApplyCorpMyNum"
                    >
                        反映
                    </button>
                </div>
            </template>
            <template #sub-content>
                <HelpText>
                    <a
                        class="customer-info-section-link"
                        href="https://www.houjin-bangou.nta.go.jp/"
                        target="_blank"
                        referrerpolicy="no-referrer"
                    >
                        ご不明の方はこちら&nbsp;
                        <font-awesome-icons icon="fa-solid fa-window-restore" />
                    </a>
                </HelpText>
                <FieldErrorText v-if="errors.corpMyNumber">{{
                    errors.corpMyNumber
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>
 -->
        <!-- 部署名 -->
        <FormFieldGroup
            :valid="!!customerInfo.department && !errors.department"
            :error="!!errors.department"
        >
            <template #title>{{ labels.customerInfo.department }}</template>
            <template #content>
                <InputField
                    :model-value="customerInfo.department"
                    name="customer_info_department"
                    placeholder="例）営業部"
                    block
                    :valid="!!customerInfo.department && !errors.department"
                    :error="!!errors.department"
                    @update:model-value="handleUpdate('department', $event)"
                    @keyup="handleUpdate('department', $event, true)"
                />
            </template>
            <template v-if="errors.department" #sub-content>
                <FieldErrorText>{{ errors.department }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 役職・担当 -->
        <FormFieldGroup
            :valid="!!customerInfo.position && !errors.position"
            :error="!!errors.position"
        >
            <template #title>{{ labels.customerInfo.position }}</template>
            <template #content>
                <InputField
                    name="customer_info_position"
                    :model-value="customerInfo.position"
                    placeholder="例）部長"
                    block
                    :valid="!!customerInfo.position && !errors.position"
                    :error="!!errors.position"
                    @update:model-value="handleUpdate('position', $event)"
                    @keyup="handleUpdate('position', $event, true)"
                />
            </template>
            <template v-if="errors.position" #sub-content>
                <FieldErrorText>{{ errors.position }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- お名前 -->
        <FormFieldGroup
            :valid="
                !!customerInfo.nameLastName &&
                !errors.nameLastName &&
                !!customerInfo.nameFirstName &&
                !errors.nameFirstName
            "
            :error="!!errors.nameLastName || !!errors.nameFirstName"
            required
        >
            <template #title>{{ labels.customerInfo.name }}</template>
            <template #content>
                <div class="flex-row-align-centered-content-start">
                    <div class="flex-row-align-centered-content-start">
                        <KeepText>
                            {{ labels.customerInfo.nameLastName }}:&nbsp;
                        </KeepText>
                        <InputField
                            name="customer_info_name_last_name"
                            style="width: 10rem"
                            :model-value="customerInfo.nameLastName"
                            placeholder="例）田中"
                            required
                            :valid="
                                !!customerInfo.nameLastName &&
                                !errors.nameLastName
                            "
                            :error="!!errors.nameLastName"
                            @update:model-value="
                                handleUpdate('nameLastName', $event)
                            "
                            @keyup="handleUpdate('nameLastName', $event, true)"
                        />
                    </div>
                    <div class="flex-row-align-centered-content-start">
                        <KeepText>
                            {{ labels.customerInfo.nameFirstName }}:&nbsp;
                        </KeepText>
                        <InputField
                            name="customer_info_name_first_name"
                            style="width: 10rem"
                            :model-value="customerInfo.nameFirstName"
                            placeholder="例）太郎"
                            required
                            :valid="
                                !!customerInfo.nameFirstName &&
                                !errors.nameFirstName
                            "
                            :error="!!errors.nameFirstName"
                            @update:model-value="
                                handleUpdate('nameFirstName', $event)
                            "
                            @keyup="handleUpdate('nameFirstName', $event, true)"
                        />
                    </div>
                </div>
            </template>
            <template
                v-if="!!errors.nameLastName || !!errors.nameFirstName"
                #sub-content
            >
                <FieldErrorText v-if="errors.nameLastName">{{
                    errors.nameLastName
                }}</FieldErrorText>
                <FieldErrorText v-if="errors.nameFirstName">{{
                    errors.nameFirstName
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- フリガナ -->
        <FormFieldGroup
            :valid="
                !!customerInfo.nameKanaLastName &&
                !errors.nameKanaLastName &&
                !!customerInfo.nameKanaFirstName &&
                !errors.nameKanaFirstName
            "
            :error="!!errors.nameKanaLastName || !!errors.nameKanaFirstName"
            required
        >
            <template #title>{{ labels.customerInfo.nameKana }}</template>
            <template #content>
                <div class="flex-row-align-centered-content-start">
                    <div class="flex-row-align-centered-content-start">
                        <KeepText>
                            {{ labels.customerInfo.nameKanaLastName }}:&nbsp;
                        </KeepText>
                        <InputField
                            name="customer_info_name_kana_last_name"
                            style="width: 10rem"
                            :model-value="customerInfo.nameKanaLastName"
                            placeholder="例）タナカ"
                            required
                            :pattern="patternKatakanaWithoutNumStr"
                            :valid="
                                !!customerInfo.nameKanaLastName &&
                                !errors.nameKanaLastName
                            "
                            :error="!!errors.nameKanaLastName"
                            @update:model-value="
                                handleUpdate('nameKanaLastName', $event)
                            "
                            @keyup="
                                handleUpdate('nameKanaLastName', $event, true)
                            "
                        />
                    </div>
                    <div class="flex-row-align-centered-content-start">
                        <KeepText>
                            {{ labels.customerInfo.nameKanaFirstName }}:&nbsp;
                        </KeepText>
                        <InputField
                            name="customer_info_name_kana_first_name"
                            style="width: 10rem"
                            :model-value="customerInfo.nameKanaFirstName"
                            placeholder="例）タロウ"
                            :pattern="patternKatakanaWithoutNumStr"
                            :valid="
                                !!customerInfo.nameKanaFirstName &&
                                !errors.nameKanaFirstName
                            "
                            :error="!!errors.nameKanaFirstName"
                            required
                            @update:model-value="
                                handleUpdate('nameKanaFirstName', $event)
                            "
                            @keyup="
                                handleUpdate('nameKanaFirstName', $event, true)
                            "
                        />
                    </div>
                </div>
            </template>
            <template #sub-content>
                <HelpText
                    >全角カナ、全角スペース、全角ハイフン、半角ハイフンのみ</HelpText
                >
                <FieldErrorText v-if="!!errors.nameKanaLastName">{{
                    errors.nameKanaLastName
                }}</FieldErrorText>
                <FieldErrorText v-if="!!errors.nameKanaFirstName">{{
                    errors.nameKanaFirstName
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 電話番号 -->
        <FormFieldGroup
            :valid="!!customerInfo.phoneNumber && !errors.phoneNumber"
            :error="!!errors.phoneNumber"
            required
        >
            <template #title>{{ labels.customerInfo.phoneNumber }}</template>
            <template #content>
                <InputField
                    name="customer_info_phone_number"
                    :model-value="customerInfo.phoneNumber"
                    placeholder="例）0311112222"
                    pattern="^\d{0,11}$"
                    :valid="!!customerInfo.phoneNumber && !errors.phoneNumber"
                    :error="!!errors.phoneNumber"
                    required
                    @update:model-value="handleUpdate('phoneNumber', $event)"
                    @keyup="handleUpdate('phoneNumber', $event, true)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角数字のみ、ハイフンなし</HelpText>
                <HelpText>登記上の電話番号を記載ください</HelpText>
                <FieldErrorText v-if="errors.phoneNumber">{{
                    errors.phoneNumber
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- FAX番号 -->
        <FormFieldGroup
            :valid="!!customerInfo.faxNumber && !errors.faxNumber"
            :error="!!errors.faxNumber"
        >
            <template #title>{{ labels.customerInfo.faxNumber }}</template>
            <template #content>
                <InputField
                    :model-value="customerInfo.faxNumber"
                    name="customer_info_fax_number"
                    placeholder="例）0311112222"
                    pattern="^\d{0,11}$"
                    :valid="!!customerInfo.faxNumber && !errors.faxNumber"
                    :error="!!errors.faxNumber"
                    @update:model-value="handleUpdate('faxNumber', $event)"
                    @keyup="handleUpdate('faxNumber', $event, true)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角数字のみ、ハイフンなし</HelpText>
                <FieldErrorText v-if="errors.faxNumber">{{
                    errors.faxNumber
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- メールアドレス -->
        <FormFieldGroup
            required
            :valid="!!customerInfo.email && !errors.email"
            :error="!!errors.email"
        >
            <template #title>{{ labels.customerInfo.email }}</template>
            <template #content>
                <InputField
                    :model-value="customerInfo.email"
                    name="customer_info_email"
                    type="email"
                    placeholder="例）xxxxx@example.com"
                    pattern="^[^@]+@[^@]+\.[^@]+$"
                    :valid="!!customerInfo.email && !errors.email"
                    :error="!!errors.email"
                    required
                    :maxlength="80"
                    block
                    @update:model-value="handleUpdate('email', $event)"
                    @keyup="handleUpdate('email', $event, true)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角英数記号のみ</HelpText>
                <FieldErrorText v-if="errors.email">{{
                    errors.email
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 郵便番号 -->
        <FormFieldGroup
            required
            :valid="!!customerInfo.postalCode && !errors.postalCode"
            :error="!!errors.postalCode"
        >
            <template #title>{{ labels.customerInfo.postalCode }}</template>
            <template #content>
                <InputField
                    :model-value="customerInfo.postalCode"
                    placeholder="例）1112222"
                    name="customer_info_postal_code"
                    required
                    :minlength="7"
                    :maxlength="8"
                    :pattern="postalcodePatternStr"
                    :valid="!!customerInfo.postalCode && !errors.postalCode"
                    :error="!!errors.postalCode"
                    @update:model-value="handleUpdate('postalCode', $event)"
                    @keyup="handleUpdate('postalCode', $event, true)"
                />
            </template>
            <template #sub-content>
                <HelpText>半角数字のみ、ハイフンなし</HelpText>
                <FieldErrorText v-if="errors.postalCode">{{
                    errors.postalCode
                }}</FieldErrorText>
            </template>
        </FormFieldGroup>

        <!-- 住所 -->
        <FormFieldGroup
            required
            :valid="
                !!customerInfo.addrPref &&
                !errors.addrPref &&
                !!customerInfo.addrCity &&
                !errors.addrCity &&
                !!customerInfo.addrStreet &&
                !errors.addrStreet &&
                !errors.addrOther
            "
            :error="
                !!errors.addrPref ||
                !!errors.addrCity ||
                !!errors.addrStreet ||
                !!errors.addrOther
            "
        >
            <template #title>{{ labels.customerInfo.address }}</template>
            <template #content>
                <AddrFields
                    :pref="{
                        value: customerInfo.addrPref,
                        valid: !!customerInfo.addrPref && !errors.addrPref,
                        error: !!errors.addrPref,
                        errorMessage: errors.addrPref,
                    }"
                    :city="{
                        value: customerInfo.addrCity,
                        valid: !!customerInfo.addrCity && !errors.addrCity,
                        error: !!errors.addrCity,
                        errorMessage: errors.addrCity,
                    }"
                    :street="{
                        value: customerInfo.addrStreet,
                        valid: !!customerInfo.addrStreet && !errors.addrStreet,
                        error: !!errors.addrStreet,
                        errorMessage: errors.addrStreet,
                    }"
                    :other="{
                        value: customerInfo.addrOther,
                        valid: !!customerInfo.addrOther && !errors.addrOther,
                        error: !!errors.addrOther,
                        errorMessage: errors.addrOther,
                    }"
                    name-prefix="customer_info_addr"
                    :pref-label="labels.customerInfo.addrPref"
                    :city-label="labels.customerInfo.addrCity"
                    :street-label="labels.customerInfo.addrStreet"
                    :other-label="labels.customerInfo.addrOther"
                    @update:pref="handleUpdate('addrPref', $event)"
                    @update:city="handleUpdate('addrCity', $event)"
                    @keyup:city="handleUpdate('addrCity', $event, true)"
                    @update:street="handleUpdate('addrStreet', $event)"
                    @keyup:street="handleUpdate('addrStreet', $event, true)"
                    @update:other="handleUpdate('addrOther', $event)"
                    @update:keyup="handleUpdate('addrOther', $event)"
                />
            </template>
            <template #sub-content>
                <HelpText>登記上の住所を記載ください</HelpText>
            </template>
        </FormFieldGroup>
    </FormSection>
</template>

<script lang="ts">
import { AxiosError } from "axios";
import { defineComponent } from "vue";

import {
    KATAKANA_REGEXP,
    KATAKANA_REGEXP_EXCLUDE_NUM,
    POSTALCODE_REGEXP,
} from "../../../../../../libs/patterns";
import { useWebFormStore, WebFormState } from "../../../../../../store/webform";
import FieldErrorText from "../../../../../ui/Atoms/FieldErrorText.vue";
import FormFieldGroup from "../../../../../ui/Atoms/FormFieldGroup.vue";
import HelpText from "../../../../../ui/Atoms/HelpText.vue";
import InputField from "../../../../../ui/Atoms/InputField.vue";
import KeepText from "../../../../../ui/Atoms/KeepText.vue";
import AddrFields from "../../../../../ui/Molecures/AddrFields.vue";
import CompanyField from "../../../../../ui/Molecures/CompanyField/index.vue";
import FormSection from "../../../../../ui/Molecures/FormSection.vue";

export type CustomerInfoState = WebFormState["customerInfo"];

export default defineComponent({
    name: "CustomerInfoSection",
    components: {
        FormSection,
        FormFieldGroup,
        InputField,
        FieldErrorText,
        AddrFields,
        HelpText,
        CompanyField,
        KeepText,
    },
    setup() {
        const formStore = useWebFormStore();

        const state = formStore.state;
        const setCustomerInfoState = formStore.setCustomerInfoState;
        const customerInfoErrors = formStore.customerInfoErrors;
        const retrieveAddrsByPostalCode = formStore.retrieveAddrsByPostalCode;
        const labels = formStore.labels;

        return {
            state,
            setCustomerInfoState,
            customerInfoErrors,
            retrieveAddrsByPostalCode,
            labels,
        };
    },
    data() {
        return {
            handleRetrieveAddrsByPostalCodeTimer: undefined,
        } as { handleRetrieveAddrsByPostalCodeTimer?: number };
    },
    computed: {
        customerInfo() {
            return this.state.customerInfo;
        },
        errors() {
            return this.customerInfoErrors();
        },
        patternKatakana() {
            return KATAKANA_REGEXP;
        },
        patternKatakanaStr() {
            return this.patternKatakana.toString().replace(/\//gm, "");
        },
        patternKatakanaWithoutNum() {
            return KATAKANA_REGEXP_EXCLUDE_NUM;
        },
        patternKatakanaWithoutNumStr() {
            return this.patternKatakanaWithoutNum
                .toString()
                .replace(/\//gm, "");
        },
        postalcodePattern() {
            return POSTALCODE_REGEXP;
        },
        postalcodePatternStr() {
            return this.postalcodePattern.toString().replace(/\//gm, "");
        },
    },
    methods: {
        handleUpdate(
            key: keyof Omit<CustomerInfoState, "companyName">,
            value: CustomerInfoState[keyof Omit<
                CustomerInfoState,
                "companyName"
            >],
            keyup = false
        ) {
            this.setCustomerInfoState({
                [key]: typeof value === "string" ? value.trim() : value,
            });

            if (
                keyup &&
                key === "postalCode" &&
                !!value &&
                /^\d{7}$/.test(value)
            ) {
                this.handleRetrieveAddrsByPostalCode(value);
            }
        },
        handleUpdateCompanyName(payload: CustomerInfoState["companyName"]) {
            console.debug("handleUpdateCompanyName", { ...payload });
            this.setCustomerInfoState({
                companyName: {
                    prefix: payload?.prefix,
                    name: payload?.name,
                    suffix: payload?.suffix,
                },
            });
        },
        // async handleApplyCorpMyNum() {
        //     const corpMyNumber = this.customerInfo.corpMyNumber;
        //     if (!corpMyNumber) {
        //         return;
        //     }

        //     // 法人マイナンバーから会社情報を取得して反映
        //     try {
        //         const {
        //             name: companyName,
        //             name_kana: companyNameKana,
        //             postal_code: postalCode,
        //             addr_pref: addrPref,
        //             addr_city: addrCity,
        //             addr_street_other: addrStreet,
        //         } = await retrieveCorpInfoByHoujinBangou(corpMyNumber);

        //         const { prefix, name, suffix } =
        //             extractCompanyNames(companyName);
        //         const p = prefix === NOT_AVAILABLE ? "" : prefix;
        //         const s = suffix === NOT_AVAILABLE ? "" : suffix;
        //         const extractedCompanyNames = {
        //             prefix: p,
        //             name,
        //             suffix: s,
        //         };

        //         const payload: Partial<CustomerInfoState> = {
        //             // calculatedCompanyName: companyName,
        //             companyName: extractedCompanyNames,
        //             companyNameKana,
        //             postalCode,
        //             addrPref,
        //             addrCity,
        //             addrStreet,
        //             addrOther: "",
        //         };
        //         console.debug("handleApplyCorpMyNum", { ...payload });

        //         this.setCustomerInfoState(payload);
        //     } catch (e: unknown) {
        //         let errors: string[];
        //         if (e instanceof ApiError) {
        //             errors = await e.extractErrorMessages();
        //         } else if (e instanceof Error) {
        //             errors = [e.message];
        //         } else {
        //             errors = [`${e}`];
        //         }

        //         alert(errors.join(`\n`));
        //     }
        // },
        async handleRetrieveAddrsByPostalCode(postalCode: string) {
            if (
                typeof this.handleRetrieveAddrsByPostalCodeTimer !== "undefined"
            ) {
                clearTimeout(this.handleRetrieveAddrsByPostalCodeTimer);
                this.handleRetrieveAddrsByPostalCodeTimer = undefined;
            }

            this.handleRetrieveAddrsByPostalCodeTimer = window.setTimeout(
                async () => {
                    try {
                        const { pref, city, street, other_addr } = (
                            await this.retrieveAddrsByPostalCode(postalCode)
                        ).data;
                        this.setCustomerInfoState({
                            addrPref: pref,
                            addrCity: city,
                            addrStreet: street,
                            addrOther:
                                other_addr || this.customerInfo.addrOther,
                        });
                    } catch (e: unknown) {
                        if (e instanceof AxiosError) {
                            console.debug(
                                "errors",
                                { ...e },
                                e.response?.status
                            );
                            switch (e.response?.status) {
                                case 403: {
                                    this.setCustomerInfoState({
                                        addrPref: "",
                                        addrCity: "",
                                        addrStreet: "",
                                        // addrOther: "",
                                    });
                                    alert(e.response.data.message);
                                    break;
                                }
                                case 500:
                                    alert(e.response.data.message);
                                    break;
                                default:
                                    alert(e.message);
                                    break;
                            }
                        } else if (e instanceof Error) {
                            alert(e.message);
                            throw e;
                        } else {
                            alert(`${e}`);
                            throw e;
                        }
                    }
                },
                100
            );
        },
    },
});
</script>

<style lang="scss" scoped>
.customer-info-section {
    .customer-info-section-link {
        display: inline-flex;
        align-items: center;

        font-size: 0.825rem;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    .customer-info-section-button {
        margin: 0;
        padding: 0.2rem 0.75rem;
        border: 1px solid #808080;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;

        background-color: #eee;
        &:hover {
            background-color: #ddd;
        }
        &:active {
            background-color: #ccc;
        }
    }

    .flex-row-align-centered-content-start {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0.5rem;

        & > * + * {
            margin-right: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .grid-container {
        display: grid;
        grid-column-end: 2 2;
        grid-template-columns: auto 1fr;
        // align-items: center;
        align-items: baseline;
        row-gap: 0.5rem;

        .field-label {
            text-align: right;
            font-size: 0.9rem;
        }
        .field-set {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
        }
    }
}
</style>
